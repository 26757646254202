import { useState } from 'react';
import WelcomeModalFeature from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-feature';
import { BoltIcon, FolderOpenIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
const WelcomeModalAllContacts = () => {
  const [loaded, setLoaded] = useState(false);
  const subheading = 'Simplify Your Contact Management';
  const title = 'All Contacts';
  const description = 'Streamline your contact management process and supercharge your productivity. Say goodbye to the hassle of navigating through complex CRM profiles. With our intuitive interface, finding, sorting, and engaging with your contacts has never been easier.';
  const imgSrc = '/assets/img/welcome-modal/all-contacts.png';
  const features = [{
    name: 'Effortless Organization: ',
    description: "Easily organize your contacts with advanced sorting and filtering options. Whether it's prospects, customers, or employees, stay on top of your contacts effortlessly.",
    icon: FolderOpenIcon
  }, {
    name: 'Quick Access:',
    description: 'Instantly access detailed CRM profiles with just a few clicks. Get all the information you need at your fingertips without wasting time searching through multiple platforms.',
    icon: BoltIcon
  }, {
    name: 'Enhanced Engagement:',
    description: 'Seamlessly engage with your contacts directly from the All Contacts page. Send personalized messages, schedule follow-ups, and nurture relationships with ease.',
    icon: PlusCircleIcon
  }];
  return <WelcomeModalFeature setLoaded={setLoaded} subheading={subheading} title={title} description={description} features={features} imgSrc={imgSrc} data-sentry-element="WelcomeModalFeature" data-sentry-component="WelcomeModalAllContacts" data-sentry-source-file="welcome-modal-all-contacts.tsx" />;
};
export default WelcomeModalAllContacts;