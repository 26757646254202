import { Menu as MenuIcon } from '@/assets/icons/menu';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useAuth } from '../../../../lib/hooks/use-auth';
import { NotificationsButton } from '../notifications-button/notifications-button';
import type { FC } from 'react';
import type { AppBarProps } from '@mui/material';
import { useAppSelector } from '@/lib/state/hooks';
import DashboardUserbarCallCenterComponent from '@/components/_shared/call-center/dashboard-user-bar-call-center-component';
import IvrCount from '@/layouts/dashboard/old/dashboard-userbar/ivr-count';
import CalendarNotificationCard from '@/layouts/dashboard/old/dashboard-userbar/calendar-notification';
import useTwilioDevice from '@/lib/hooks/use-twilio-device';
import { UserButton } from '@/layouts/dashboard/old/user-button/user-button';
interface DashboardUserbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
}
const DashboardUserbarRoot = styled(AppBar)(({
  theme
}) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light' ? {
    boxShadow: theme.shadows[3]
  } : {
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    boxShadow: 'none'
  })
}));
const DisplayStoreName = () => {
  const store_id = useAuth()?.user?.store_id;
  const {
    data: storeData
  } = useGetStoreSettingsQuery(store_id);
  const storeName = storeData?.data?.name ?? 'Store Name';
  return <Box sx={{
    alignItems: 'center',
    display: 'flex',
    ml: 1
  }} data-sentry-element="Box" data-sentry-component="DisplayStoreName" data-sentry-source-file="dashboard-userbar.tsx">
      <Typography color="textPrimary" variant="subtitle2" data-sentry-element="Typography" data-sentry-source-file="dashboard-userbar.tsx">
        {storeName}
      </Typography>
    </Box>;
};

/* Description: This component is the userbar that appears at the top of the dashboard layout. It contains the sidebar toggle button, call center component, calendar notification card, IVR notification counter, store name, notifications button, and user button. */

export const DashboardUserbar: FC<React.PropsWithChildren<DashboardUserbarProps>> = props => {
  const {
    onOpenSidebar,
    ...other
  } = props;
  const isCallCenterActive = useAppSelector(state => state.callCenter.widgetVisibility);
  const token = useAppSelector(state => state.callCenter.twilioToken);
  const twilioDevice = useTwilioDevice(token);

  //get ivt current calls counter from redux
  const ivrCurrentCallsCounter: number = useAppSelector(state => state.callCenter.ivrCurrentCallsCounter);
  return <>
      <DashboardUserbarRoot {...other} data-sentry-element="DashboardUserbarRoot" data-sentry-source-file="dashboard-userbar.tsx">
        {/* Userbar content */}
        <Toolbar disableGutters sx={{
        minHeight: 64,
        left: 0,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }} data-sentry-element="Toolbar" data-sentry-source-file="dashboard-userbar.tsx">
          {/* Sidebar toggle button */}
          <IconButton onClick={onOpenSidebar} sx={{
          display: {
            xs: 'inline-flex',
            xl: 'inline-flex'
          }
        }} data-sentry-element="IconButton" data-sentry-source-file="dashboard-userbar.tsx">
            <MenuIcon fontSize="small" data-sentry-element="MenuIcon" data-sentry-source-file="dashboard-userbar.tsx" />
          </IconButton>

          {/* CALL CENTER - Phone Component */}
          {isCallCenterActive && <DashboardUserbarCallCenterComponent twilioDevice={twilioDevice} />}
          <CalendarNotificationCard data-sentry-element="CalendarNotificationCard" data-sentry-source-file="dashboard-userbar.tsx" />

          <Box sx={{
          display: 'flex'
        }} data-sentry-element="Box" data-sentry-source-file="dashboard-userbar.tsx">
            {/* <ColorModeButton /> */}
            {/* <LanguageButton /> */}
            {/* <ContentSearchButton /> */}
            {/* <MessagesButton /> */}

            {/* IVR NOTIFICATION COUNTER - POP UP DIALOG */}
            {ivrCurrentCallsCounter > 0 && <IvrCount ivrCurrentCallsCounter={ivrCurrentCallsCounter} />}

            <DisplayStoreName data-sentry-element="DisplayStoreName" data-sentry-source-file="dashboard-userbar.tsx" />
            <NotificationsButton data-sentry-element="NotificationsButton" data-sentry-source-file="dashboard-userbar.tsx" />
            <UserButton data-sentry-element="UserButton" data-sentry-source-file="dashboard-userbar.tsx" />
          </Box>
        </Toolbar>
      </DashboardUserbarRoot>
    </>;
};
DashboardUserbar.propTypes = {
  onOpenSidebar: PropTypes.func
};