import useClassNames from '@/lib/hooks/useClassNames';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
const TailwindIndicator = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenOrientation, setScreenOrientation] = useState(window.screen.orientation.type);
  const [pixelRatio, setPixelRatio] = useState(window.devicePixelRatio);
  const [showDebugPanel, setShowDebugPanel] = useState(() => {
    const saved = localStorage.getItem('auth_debug_panel_open');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const breakpoints = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536
  };
  const currentBreakpoint = Object.entries(breakpoints).reverse().find(([, value]) => screenWidth >= value)?.[0];
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      setScreenOrientation(window.screen.orientation.type);
      setPixelRatio(window.devicePixelRatio);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const toggleDebugPanel = () => {
    const currentState = localStorage.getItem('auth_debug_panel_open');
    const newState = currentState === 'true' ? false : true;
    localStorage.setItem('auth_debug_panel_open', JSON.stringify(newState));
    // Dispatch a custom event to notify AuthDebugPanel
    window.dispatchEvent(new CustomEvent('debug_panel_toggle', {
      detail: newState
    }));
  };
  const tailwind_dev_bar = process.env.NEXT_PUBLIC_TAILWIND_DEV_BAR;
  return <Box sx={{
    zIndex: theme => theme.zIndex.appBar + 3000
  }} data-sentry-element="Box" data-sentry-component="TailwindIndicator" data-sentry-source-file="tailwind-indicator.tsx">
      <div className={useClassNames(tailwind_dev_bar === 'true' ? 'bg-gray-900' : 'hidden', 'fixed bottom-0 left-0 m-1 flex h-6 w-auto items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white')}>
        <div className="flex items-center space-x-2">
          <span>{currentBreakpoint}</span>
          <span>
            {screenWidth} x {screenHeight}
          </span>
          <span>
            Orientation:{' '}
            {screenOrientation.replace('portrait-primary', 'Portrait').replace('landscape-primary', 'Landscape')}
          </span>
          <span>Pixel Ratio: {pixelRatio}</span>
          <button onClick={toggleDebugPanel} className="ml-2 rounded bg-blue-600 px-2 py-0.5 text-xs font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            {showDebugPanel ? 'Hide Debug' : 'Debug 👀'}
          </button>
        </div>
      </div>
    </Box>;
};
export default TailwindIndicator;