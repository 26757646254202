//src/components/layouts/dashboard/dashboard-userbar/notifications-button/notifications-button.tsx

/**
 * @file NotificationsButton.tsx
 * @description A notification bell component that serves as the primary notification center access point.
 * Displays a bell icon with an unread count badge and provides a popover with recent notifications.
 *
 * Component Architecture:
 * - Stateless functional component using React hooks
 * - RTK Query for data fetching and real-time updates
 * - Material-UI components for UI elements
 * - Tailwind CSS for styling
 *
 * Key Features:
 * - Real-time unread notification counter (0-99)
 * - Popover with latest 10 unread notifications
 * - Automatic polling for updates (30s interval)
 * - Error state handling
 * - Responsive design
 * - Keyboard accessible
 * - Direct navigation to full notification history
 *
 * State Management:
 * - Uses RTK Query for notification data
 * - Memoized calculations for performance
 * - Popover state managed via custom hook
 *
 * Performance Optimizations:
 * - Memoized callback functions
 * - Memoized derived values
 * - Controlled re-render conditions
 * - Efficient polling strategy
 *
 * Accessibility Features:
 * - ARIA labels
 * - Keyboard navigation support
 * - Focus management
 * - Screen reader friendly
 *
 * @example
 * // Basic usage in a layout component
 * import { NotificationsButton } from './NotificationsButton';
 *
 * const Header = () => (
 *   <header>
 *     <NotificationsButton />
 *   </header>
 * );
 */

import React, { FC, useCallback, useMemo } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { BellIcon } from '@/assets/icons/bell-icon';
import { usePopover } from '@/lib/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import { useGetUnreadNotificationsQuery } from '@/lib/state/services/notifications-api';
import { useRouter } from 'next/router';
import useNotifications from '@/lib/hooks/notifications/use-notifications';

/**
 * NotificationsButton Component
 *
 * A self-contained notification center trigger that displays unread notifications
 * count and provides access to recent notifications via a popover.
 *
 * @component
 * @returns {JSX.Element} Rendered component
 *
 * Internal State:
 * - Popover visibility (via usePopover hook)
 * - Unread notifications data (via RTK Query)
 * - Error state handling
 *
 * Data Flow:
 * 1. RTK Query fetches unread notifications
 * 2. Unread count displayed in badge
 * 3. Click triggers popover with notifications list
 * 4. "View All" navigates to full notification history
 */
export const NotificationsButton: FC = () => {
  // Hooks initialization
  const router = useRouter();
  const popover = usePopover<HTMLButtonElement>();
  const {
    handleMarkAllAsRead,
    handleRemoveOne
  } = useNotifications();

  /**
   * RTK Query hook for fetching unread notifications
   * - Polls every 30 seconds for updates
   * - Refetches on component mount
   * - Provides error state handling
   */
  const {
    data: notificationsData,
    isError
  } = useGetUnreadNotificationsQuery(undefined, {
    // pollingInterval: 30000, // Poll every 30 seconds
    refetchOnMountOrArgChange: true
  });

  /**
   * Memoized derived values
   * - Unread notifications count
   * - Notifications list
   * - Error state
   */

  const unreadCount = useMemo(() => notificationsData?.meta?.total ?? 0, [notificationsData?.meta?.total]);
  const notifications = useMemo(() => notificationsData?.data ?? [], [notificationsData?.data]);

  /**
   * Handles bell icon click
   * Opens popover if no errors present
   */
  const handleBellClick = useCallback(() => {
    if (!isError) {
      popover.handleOpen();
    }
  }, [isError, popover]);

  /**
   * Handles "View All" navigation
   * Routes to settings page with notifications tab
   */
  const handleViewAllNotifications = useCallback(() => {
    router.push('/dashboard/settings?tab=notifications');
    popover.handleClose();
  }, [router, popover]);

  /**
   * Handles "View All" navigation
   * Routes to settings page with notifications tab
   */
  if (isError) {
    return <Tooltip title="Unable to load notifications">
        <span>
          <IconButton disabled>
            <BellIcon className="text-gray-400" />
          </IconButton>
        </span>
      </Tooltip>;
  }
  return <>
      <Tooltip title="Notifications" data-sentry-element="Tooltip" data-sentry-source-file="notifications-button.tsx">
        <IconButton ref={popover.anchorRef} onClick={handleBellClick} className="relative" data-sentry-element="IconButton" data-sentry-source-file="notifications-button.tsx">
          <Badge color="error" badgeContent={unreadCount} max={99} overlap="circular" anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} data-sentry-element="Badge" data-sentry-source-file="notifications-button.tsx">
            <BellIcon className={`w-6 h-6 ${unreadCount > 0 ? 'text-gray-800 hover:text-gray-900' : 'text-gray-600 hover:text-gray-700'}`} data-sentry-element="BellIcon" data-sentry-source-file="notifications-button.tsx" />
          </Badge>
        </IconButton>
      </Tooltip>

      <NotificationsPopover anchorEl={popover.anchorRef.current} notifications={notifications} onClose={popover.handleClose} onViewAll={handleViewAllNotifications} open={popover.open} data-sentry-element="NotificationsPopover" data-sentry-source-file="notifications-button.tsx" />
    </>;
};