import Image from 'next/image';
type Props = {
  welcomeConfetti: () => void;
};
const WelcomeModalStartPage = (props: Props) => {
  return <div className="relative flex flex-col items-center justify-center w-full h-full bg-center bg-cover" style={{
    backgroundImage: 'url("/assets/img/welcome-modal/confetti-bg.png")'
  }} data-sentry-component="WelcomeModalStartPage" data-sentry-source-file="welcome-modal-start-page.tsx">
      <div className="py-8 overflow-hidden bg-transparent rounded-t-xl sm:py-12">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="px-6 mx-auto max-w-7xl lg:px-8">
            <div className="mx-auto max-w-2xl rounded-md bg-[#f9fafb] p-4 border-4 border-orange-500 shadow-lg sm:text-center">
              <p className="mt-2 text-3xl tracking-tight text-orange-600 font-proximaBlack sm:text-4xl">
                Go Bananas!
              </p>
              <p className="mt-6 text-xl font-black leading-8 text-gray-900">
                You’re set up and ready to rock
              </p>
            </div>
          </div>
          <div className="relative pt-16 overflow-hidden">
            <div onClick={props.welcomeConfetti} className="flex justify-center px-6 mx-auto cursor-pointer max-w-7xl lg:px-8">
              <Image src="/assets/img/welcome-modal/partying-face.png" alt="Party Emoji" width={410} height={410} priority data-sentry-element="Image" data-sentry-source-file="welcome-modal-start-page.tsx" />{' '}
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default WelcomeModalStartPage;