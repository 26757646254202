//src/lib/state/services/notifications-api.ts

/**
 * @file notifications-api.ts
 * @description A comprehensive API module for managing notifications using RTK Query.
 * This module handles all notification-related operations including fetching,
 * marking as read, and bulk operations. It supports both the notification bell
 * (unread notifications) and the full notifications list in settings.
 *
 * Key Features:
 * - Separate endpoints for bell notifications (limited to 10) and full list
 * - Real-time optimistic updates for better UX
 * - Automatic cache invalidation and synchronization
 * - Type-safe request/response handling
 * - Robust error handling and rollback support
 *
 * API Endpoints:
 * - GET    /v2/notifications/unread          - Fetch unread notifications (bell)
 * - GET    /v2/notifications?page={number}   - Fetch all notifications (settings)
 * - POST   /v2/notifications/mark-as-read    - Mark single notification as read
 * - POST   /v2/notifications/mark-all-as-read- Mark all notifications as read
 * - DELETE /v2/notifications                 - Delete notifications
 *
 * Usage Examples:
 * ```typescript
 * // Bell notifications (limited to 10 unread)
 * const { data: unreadNotifications } = useGetUnreadNotificationsQuery();
 *
 * // Full notifications list with pagination
 * const { data: allNotifications } = useGetNotificationsQuery({ page: 1 });
 *
 * // Mark as read
 * const [markAsRead] = useMarkNotificationAsReadMutation();
 * await markAsRead({ notification_id: "123" });
 *
 * // Mark all as read
 * const [markAllAsRead] = useMarkAllNotificationsAsReadMutation();
 * await markAllAsRead();
 * ```
 *
 * Cache Invalidation Strategy:
 * - Uses separate tags for unread and all notifications
 * - Automatically updates both lists when marking as read
 * - Handles optimistic updates with rollback support
 *
 * @module notifications-api
 * @requires @reduxjs/toolkit/query/react
 */

import { api } from './api';

// #region Types
/**
 * Metadata associated with a notification
 * Contains additional information specific to different notification types
 */
export interface NotificationMetadata {
  id?: number;
  store_id?: number;
  customer_store_id?: number;
  from_number?: string;
  to_number?: string;
  channel?: string;
  content?: string;
  call_start_at?: string;
  call_completed_at?: string;
  duration_in_seconds?: number;
  status?: string;
  last_updated_at?: string;
  will_send_on?: string;
}

/**
 * Core notification structure
 * Represents a single notification with all its properties
 */
export interface SystemNotification {
  id: string; // Unique identifier
  type: string; // Notification type (e.g., 'voice_call', 'message')
  content: string; // Main notification content
  channel: string; // Delivery channel
  metadata: NotificationMetadata; // Additional type-specific data
  read_at: string | null; // Timestamp when marked as read
  created_at: string; // Creation timestamp
  updated_at: string; // Last update timestamp
}

/**
 * Laravel pagination links structure
 */
export interface PaginationLinks {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
}

/**
 * Laravel pagination metadata
 */
export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
  unread_count: number;
}

/**
 * Complete API response structure
 */
export interface NotificationResponse {
  data: SystemNotification[];
  links: PaginationLinks;
  meta: PaginationMeta;
}

/**
 * Request parameters
 */
export interface GetNotificationsArgs {
  page: number;
}

export interface MarkAsReadRequest {
  notification_id: string;
}

export interface DeleteNotificationsRequest {
  notification_ids: string[];
}

export interface NotificationActionResponse {
  message: string;
  success: boolean;
}

/**
 * API request/response types
 */
export interface GetUnreadNotificationsArgs {
  limit?: number; // Maximum number of unread notifications to fetch
}

/**
 * API endpoints definition
 */
export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Fetch unread notifications for the bell dropdown
     * @param args Optional configuration including limit (defaults to 10)
     * @returns NotificationResponse with unread notifications
     */
    getUnreadNotifications: build.query<
      NotificationResponse,
      GetUnreadNotificationsArgs | void
    >({
      query: (args) => ({
        url: 'v2/notifications/unread',
        method: 'GET',
        params: {
          limit: args?.limit || 10,
          unread_only: true
        }
      }),
      transformResponse: (
        response: NotificationResponse
      ): NotificationResponse => {
        return {
          ...response,
          data: response.data || [],
          meta: {
            ...response.meta,
            // Use the total from meta as the unread_count
            unread_count: response.meta?.total || 0
          }
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'UnreadNotifications' as const,
                id
              })),
              { type: 'UnreadNotifications', id: 'LIST' }
            ]
          : [{ type: 'UnreadNotifications', id: 'LIST' }]
    }),
    /**
     * Fetch all notifications with pagination
     * Used in the settings page for full notification history
     * @param page Page number for pagination
     * @returns NotificationResponse with paginated notifications
     */
    getNotifications: build.query<NotificationResponse, GetNotificationsArgs>({
      query: ({ page = 1 }) => ({
        url: 'v2/notifications',
        method: 'GET',
        params: { page }
      }),
      // Transform response to ensure data integrity
      transformResponse: (
        response: NotificationResponse
      ): NotificationResponse => {
        return {
          ...response,
          data: response.data || [],
          meta: {
            ...response.meta,
            unread_count: response.meta?.unread_count || 0
          }
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'NotificationsData' as const,
                id
              })),
              { type: 'NotificationsData', id: 'LIST' }
            ]
          : [{ type: 'NotificationsData', id: 'LIST' }]
    }),

    /**
     * Mark a single notification as read
     * Updates both unread and full notification lists
     * @param notification_id ID of the notification to mark as read
     * @returns Success status
     */
    markNotificationAsRead: build.mutation<
      NotificationActionResponse,
      MarkAsReadRequest
    >({
      query: (data) => ({
        url: 'v2/notifications/mark-as-read',
        method: 'POST',
        body: data
      }),
      // Optimistic updates for both lists
      async onQueryStarted({ notification_id }, { dispatch, queryFulfilled }) {
        // Update main notifications list
        const patchMainList = dispatch(
          notificationsApi.util.updateQueryData(
            'getNotifications',
            { page: 1 },
            (draft) => {
              const notification = draft.data.find(
                (n) => n.id === notification_id
              );
              if (notification) {
                notification.read_at = new Date().toISOString();
              }
              if (draft.meta) {
                draft.meta.unread_count = Math.max(
                  0,
                  draft.meta.unread_count - 1
                );
              }
            }
          )
        );

        // Update unread notifications list
        const patchUnreadList = dispatch(
          notificationsApi.util.updateQueryData(
            'getUnreadNotifications',
            undefined,
            (draft) => {
              draft.data = draft.data.filter((n) => n.id !== notification_id);
              if (draft.meta) {
                draft.meta.unread_count = Math.max(
                  0,
                  draft.meta.unread_count - 1
                );
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchMainList.undo();
          patchUnreadList.undo();
        }
      },
      invalidatesTags: (result, error, { notification_id }) => [
        { type: 'NotificationsData', id: notification_id },
        { type: 'UnreadNotifications', id: notification_id }
      ]
    }),

    /**
     * Mark all notifications as read
     * Clears unread list and updates full notification list
     * @returns Success status
     */
    markAllNotificationsAsRead: build.mutation<
      NotificationActionResponse,
      void
    >({
      query: () => ({
        url: 'v2/notifications/mark-all-as-read',
        method: 'POST'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // Update main notifications list
        const patchMainList = dispatch(
          notificationsApi.util.updateQueryData(
            'getNotifications',
            { page: 1 },
            (draft) => {
              const now = new Date().toISOString();
              draft.data.forEach((notification) => {
                if (!notification.read_at) {
                  notification.read_at = now;
                }
              });
              if (draft.meta) {
                draft.meta.unread_count = 0;
              }
            }
          )
        );

        // Update unread notifications list
        const patchUnreadList = dispatch(
          notificationsApi.util.updateQueryData(
            'getUnreadNotifications',
            undefined,
            (draft) => {
              draft.data = [];
              if (draft.meta) {
                draft.meta.unread_count = 0;
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchMainList.undo();
          patchUnreadList.undo();
        }
      },
      invalidatesTags: [
        { type: 'NotificationsData', id: 'LIST' },
        { type: 'UnreadNotifications', id: 'LIST' }
      ]
    }),

    /**
     * Delete specified notifications
     * Removes notifications from both unread and full lists
     * @param notification_ids Array of notification IDs to delete
     * @returns Success status
     */
    deleteNotifications: build.mutation<
      NotificationActionResponse,
      DeleteNotificationsRequest
    >({
      query: (data) => ({
        url: 'v2/notifications/delete',
        method: 'DELETE',
        body: data
      }),
      async onQueryStarted({ notification_ids }, { dispatch, queryFulfilled }) {
        // Update both lists optimistically
        const patchMainList = dispatch(
          notificationsApi.util.updateQueryData(
            'getNotifications',
            { page: 1 },
            (draft) => {
              draft.data = draft.data.filter(
                (n) => !notification_ids.includes(n.id)
              );
              if (draft.meta) {
                draft.meta.total = Math.max(
                  0,
                  draft.meta.total - notification_ids.length
                );
              }
            }
          )
        );

        const patchUnreadList = dispatch(
          notificationsApi.util.updateQueryData(
            'getUnreadNotifications',
            undefined,
            (draft) => {
              draft.data = draft.data.filter(
                (n) => !notification_ids.includes(n.id)
              );
              if (draft.meta) {
                draft.meta.unread_count = Math.max(
                  0,
                  draft.meta.unread_count - notification_ids.length
                );
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchMainList.undo();
          patchUnreadList.undo();
        }
      },
      invalidatesTags: (result, error, { notification_ids }) => [
        ...notification_ids.map((id) => ({
          type: 'NotificationsData' as const,
          id
        })),
        ...notification_ids.map((id) => ({
          type: 'UnreadNotifications' as const,
          id
        }))
      ]
    })
  }),
  overrideExisting: true
});

// Export hooks for use in components
export const {
  useGetNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useMarkAllNotificationsAsReadMutation,
  useDeleteNotificationsMutation
} = notificationsApi;
