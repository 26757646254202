import { SplashScreen } from '@/components/_shared/splash-screen';
import { AuthGuard } from '@/components/authentication/auth-guard';
import { DashboardLayout } from '@/layouts/dashboard/old/dashboard-layout';
import { SidebarContext } from '@/layouts/dashboard/old/dashboard-userbar/sidebar-context';
import { useAuth } from '@/lib/hooks/use-auth';
import NotificationHandler from '@/lib/services/notifications/notification-handler';
import { GetLayoutFn } from '@/pages/_app';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
interface PersistentLayoutProps {
  children: ReactNode;
  getLayout?: GetLayoutFn;
}
export function PersistentLayout({
  children,
  getLayout
}: PersistentLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const {
    isInitialized,
    isAuthenticated,
    isLoading
  } = useAuth();
  const router = useRouter();
  useEffect(() => {
    const handleStart = () => setIsNavigating(true);
    const handleComplete = () => setIsNavigating(false);
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  // Check if we're on a public or onboarding route
  const isPublicRoute = router.pathname.startsWith('/authentication/') || router.pathname === '/' || router.pathname === '/404' || router.pathname === '/401' || router.pathname === '/maintenance' || router.pathname === '/get-started' || router.pathname.startsWith('/onboarding/');

  // Show splash screen only during initial load or navigation
  if (!isInitialized && !isPublicRoute || isNavigating || isLoading) {
    return <SplashScreen />;
  }

  // If page has its own getLayout function, use it
  if (getLayout) {
    const layoutContent = getLayout(<>{children}</>);
    // If this is a guest route and user is authenticated, GuestGuard will handle redirect
    if (isAuthenticated && isPublicRoute) {
      return <>{layoutContent}</>;
    }
    return layoutContent;
  }

  // If not authenticated and not on a public route, AuthGuard will handle redirect
  if (!isAuthenticated && !isPublicRoute) {
    return <AuthGuard>{children}</AuthGuard>;
  }

  // For public routes without getLayout, render directly
  if (isPublicRoute) {
    return <>{children}</>;
  }

  // For authenticated routes, render with dashboard layout
  return <SidebarContext.Provider value={{
    isSidebarOpen,
    setSidebarOpen: setIsSidebarOpen
  }} data-sentry-element="unknown" data-sentry-component="PersistentLayout" data-sentry-source-file="persistent-layout.tsx">
      <NotificationHandler data-sentry-element="NotificationHandler" data-sentry-source-file="persistent-layout.tsx" />
      <DashboardLayout data-sentry-element="DashboardLayout" data-sentry-source-file="persistent-layout.tsx">
        <div className="min-h-screen">{children}</div>
      </DashboardLayout>
    </SidebarContext.Provider>;
}