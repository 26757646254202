import { useState } from 'react';
import WelcomeModalFeature from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-feature';
import { ChatBubbleBottomCenterTextIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
const WelcomeModalOptimizeAccount = () => {
  const subheading = 'Maximize Your RipeMetrics Experience';
  const title = 'Last, Optimize Your Account';
  const description = "Let's help you maximize the value of your RipeMetrics service. Our Optimize Account page guides you through essential setup steps to ensure you're getting the most out of your account.";
  const imgSrc = '/assets/img/welcome-modal/optimize-account.png';
  const features = [{
    name: 'Website Chat:',
    description: 'Power your website with our AI chat to provide instant customer support.',
    icon: ChatBubbleBottomCenterTextIcon
  }, {
    name: 'Email:',
    description: 'Integrate your work email to streamline communication within the platform.',
    icon: EnvelopeIcon
  }, {
    name: 'Phone & Text:',
    description: 'Set up phone tracking with analytics and enable text messaging engagement to connect with customers.',
    icon: PhoneIcon
  }];
  const [loaded, setLoaded] = useState(false);
  return <WelcomeModalFeature setLoaded={setLoaded} subheading={subheading} title={title} description={description} features={features} imgSrc={imgSrc} data-sentry-element="WelcomeModalFeature" data-sentry-component="WelcomeModalOptimizeAccount" data-sentry-source-file="welcome-modal-optimize-account.tsx" />;
};
export default WelcomeModalOptimizeAccount;