import { useState } from 'react';
import WelcomeModalFeature from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-feature';
import { ChatBubbleLeftRightIcon, ClipboardDocumentCheckIcon, IdentificationIcon } from '@heroicons/react/20/solid';
const WelcomeModalCommunicationCenter = () => {
  const [loaded, setLoaded] = useState(false);
  const subheading = 'Optimized Customer Support';
  const title = 'Communication CRM';
  const description = 'Simplify your customer communication with the Communication CRM. It combines CRM and Customer Service tools, allowing you to interact via Text, Web Chat, Email, Social Media, and Phone. Manage tasks, add notes, set reminders, and collaborate seamlessly with your team.';
  const imgSrc = '/assets/img/welcome-modal/communication-theater.png';
  const features = [{
    name: 'CRM Integration:',
    description: 'Combine and organize all of your customer communication in one tool for increased efficiency and simplicity improving efficiency.',
    icon: IdentificationIcon
  }, {
    name: 'Multi-channel Communication:',
    description: 'Engage with customers through various channels including Text, Web Chat, Email, Social Media, and Phone, all within a unified platform.',
    icon: ChatBubbleLeftRightIcon
  }, {
    name: 'Task Management:',
    description: 'Effortlessly manage tasks and appointments, stay organized with Calendar Events & Reminders, and ensure timely follow-ups with customers.',
    icon: ClipboardDocumentCheckIcon
  }];
  return <WelcomeModalFeature setLoaded={setLoaded} subheading={subheading} title={title} description={description} features={features} imgSrc={imgSrc} data-sentry-element="WelcomeModalFeature" data-sentry-component="WelcomeModalCommunicationCenter" data-sentry-source-file="welcome-modal-communication-center.tsx" />;
};
export default WelcomeModalCommunicationCenter;