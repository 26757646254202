import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useAppDispatch } from '../state/hooks';
import { logOut, setCredentials } from '../state/slices/auth-slice';
import { setTwilioToken, identity, updateCallCenterName } from '@/lib/state/slices/call-center-slice';
import { updateUserPermissions } from '@/lib/state/slices/user-permissions-slice';
import { persistor } from '@/lib/state/store';
import axios from 'axios';
import type { User } from '../types/user';
const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`;
const sanctumBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}
interface AuthContextValue extends State {
  login: (loginFormData: {
    email: string;
    password: string;
    redirectPath?: string;
  }) => Promise<unknown>;
  logout: () => Promise<void>;
  isLoading: boolean;
}
const initialState: State = {
  isInitialized: false,
  isAuthenticated: false,
  user: null
};
type Action = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
} | {
  type: 'LOGIN';
  payload: {
    user: User;
  };
} | {
  type: 'LOGOUT';
};
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};
export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  isLoading: false
});
export const AuthProvider: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const dispatchRedux = useAppDispatch();
  const router = useRouter();
  const clearAuthState = () => {
    window.localStorage.clear();
    posthog.reset();
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
  };
  const initializeAuth = async (token?: string) => {
    try {
      const accessToken = token || window.localStorage.getItem('accessToken');
      if (!accessToken) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        return null;
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      const response = await axios.get(`${baseUrl}/user`);
      if (response?.data?.user) {
        const user = response.data.user as User;
        dispatchRedux(setCredentials({
          token: 'none',
          user,
          assignments: response.data.assignments
        }));
        dispatchRedux(setTwilioToken(user.permissions.token));
        dispatchRedux(identity(user.permissions.identity));
        dispatchRedux(updateCallCenterName(user.call_center_name));
        dispatchRedux(updateUserPermissions(user.permissions));

        // Identify user in PostHog
        posthog.identify(user.id, {
          email: user.email,
          name: user.name
        });
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
        return user;
      }
      clearAuthState();
      return null;
    } catch (error) {
      console.error('Initialize auth error:', error);
      clearAuthState();
      return null;
    }
  };
  useEffect(() => {
    initializeAuth();
  }, []);
  const login = async (loginFormData: {
    email: string;
    password: string;
    redirectPath?: string;
  }): Promise<unknown> => {
    try {
      const loginData = {
        email: loginFormData.email,
        password: loginFormData.password
      };

      // Get CSRF cookie first
      await axios.get(`${sanctumBaseUrl}/sanctum/csrf-cookie`);

      // Attempt login without setting loading state
      const loginResponse = await axios.post(`${baseUrl}/login`, loginData);

      // Check if we have a token in the response
      const token = loginResponse?.data?.token;
      if (!token) {
        clearAuthState();
        return {
          error: {
            status: 401,
            data: {
              message: 'No token received in login response'
            }
          }
        };
      }

      // Only set loading state after successful credential validation
      setIsLoading(true);
      window.localStorage.setItem('accessToken', token);
      const user = await initializeAuth(token);
      if (!user) {
        setIsLoading(false);
        return {
          error: {
            status: 401,
            data: {
              message: 'No user data received'
            }
          }
        };
      }

      // Redirect to the desired path after successful login
      const redirectUrl = loginFormData.redirectPath || '/crm/communication-crm';
      await router.push(redirectUrl);
      setIsLoading(false);
      return {};
    } catch (error) {
      console.error('Login failed:', error);
      clearAuthState();
      if (axios.isAxiosError(error) && error.response) {
        return {
          error: {
            status: error.response.status,
            data: error.response.data
          }
        };
      }
      return {
        error: {
          status: 500,
          data: {
            message: 'An unexpected error occurred'
          }
        }
      };
    }
  };
  const logout = async (): Promise<void> => {
    setIsLoading(true);
    try {
      // Update auth context state first
      dispatch({
        type: 'LOGOUT'
      });

      // Clear Redux state
      dispatchRedux(logOut());
      await persistor.purge();

      // Clear storage and cookies
      localStorage.clear();
      sessionStorage.clear();

      // Clear tracking
      posthog.reset();
      document.cookie.split(';').forEach(cookie => {
        const name = cookie.split('=')[0].trim();
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${window.location.hostname};`;
      });

      // Cleanup Pusher/Echo
      if (window.Echo?.connector?.pusher) {
        try {
          window.Echo.connector.pusher.unsubscribe();
          window.Echo.connector.pusher.disconnect();
          window.Echo.connector.pusher = null;
          delete window.Echo;
        } catch (error) {
          console.warn('Error cleaning up Pusher:', error);
        }
      }

      // Make the logout request to the backend
      try {
        await axios.post(`${baseUrl}/logout`);
      } catch (error) {
        console.warn('Backend logout failed, but continuing with local logout', error);
      }

      // Clear axios default headers
      delete axios.defaults.headers.common['Authorization'];

      // Finally, redirect to login page
      setIsLoading(false);
      await router.push('/authentication/login');
    } catch (error) {
      console.error('Logout error:', error);
      // Even if there's an error, ensure we reset the state and redirect
      setIsLoading(false);
      router.push('/authentication/login').catch(console.error);
    }
  };
  return <AuthContext.Provider value={{
    ...state,
    login,
    logout,
    isLoading
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth-context.tsx">
      {children}
    </AuthContext.Provider>;
};
export const AuthConsumer = AuthContext.Consumer;