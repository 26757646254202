import Router from 'next/router';
import nProgress from 'nprogress';

export const setupNProgress = () => {
  Router.events.on('routeChangeStart', nProgress.start);
  Router.events.on('routeChangeError', nProgress.done);
  Router.events.on('routeChangeComplete', nProgress.done);
};

export const configureNProgress = (
  config: Partial<nProgress.NProgressOptions> = {}
) => {
  nProgress.configure(config);
};
