import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { DashboardUserbar } from './dashboard-userbar/dashboard-userbar';
import { SidebarContext } from '@/layouts/dashboard/old/dashboard-userbar/sidebar-context';
import { AppSidebar } from '@/layouts/app/app-sidebar';
interface DashboardLayoutProps {
  children?: ReactNode;
}
const DashboardLayoutRoot = styled('div')(({
  theme
}) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  minHeight: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('xl')]: {
    paddingLeft: 280
  }
}));
export const DashboardLayout: FC<React.PropsWithChildren<DashboardLayoutProps>> = props => {
  const {
    children
  } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  return <>
      <SidebarContext.Provider value={{
      isSidebarOpen,
      setSidebarOpen: setIsSidebarOpen
    }} data-sentry-element="unknown" data-sentry-source-file="dashboard-layout.tsx">
        <DashboardLayoutRoot data-sentry-element="DashboardLayoutRoot" data-sentry-source-file="dashboard-layout.tsx">
          <Box sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          width: '100%',
          minHeight: '100%'
        }} data-sentry-element="Box" data-sentry-source-file="dashboard-layout.tsx">
            <div className="h-full lg">{children}</div>
          </Box>
        </DashboardLayoutRoot>
        <DashboardUserbar onOpenSidebar={(): void => setIsSidebarOpen(true)} data-sentry-element="DashboardUserbar" data-sentry-source-file="dashboard-layout.tsx" />
        <AppSidebar onClose={(): void => setIsSidebarOpen(false)} open={isSidebarOpen} data-sentry-element="AppSidebar" data-sentry-source-file="dashboard-layout.tsx" />
      </SidebarContext.Provider>
    </>;
};
DashboardLayout.propTypes = {
  children: PropTypes.node
};