/**
 * @file NotificationsPopover.tsx
 * @description A popover component that displays a list of notifications with scroll functionality.
 * Serves as the container for notification items and manages the notification list display.
 *
 * Component Architecture:
 * - Functional component with TypeScript props
 * - Material-UI Popover as base container
 * - Custom scrollbar implementation
 * - Conditional rendering based on notification state
 * - Responsive design with fixed width
 *
 * Visual Structure:
 * ┌─────────────────────────────┐
 * │ Notifications (Header)      │
 * ├─────────────────────────────┤
 * │ ┌─────────────────────────┐ │
 * │ │ Notification Item 1     │ │
 * │ ├─────────────────────────┤ │
 * │ │ Notification Item 2     │ │
 * │ ├─────────────────────────┤ │
 * │ │ Notification Item 3     │ │
 * │ └─────────────────────────┘ │
 * ├─────────────────────────────┤
 * │ View All Notifications      │
 * └─────────────────────────────┘
 *
 * @example
 * ```tsx
 * <NotificationsPopover
 *   anchorEl={buttonRef.current}
 *   notifications={notificationsList}
 *   onClose={handleClose}
 *   onViewAll={handleViewAll}
 *   open={isOpen}
 * />
 * ```
 */

import React, { FC } from 'react';
import { Box, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { Scrollbar } from '@/components/_shared/scrollbar';
import NotificationEmptyState from '@/layouts/dashboard/old/notifications-button/notification-empty-state';
import NotificationItem from '@/layouts/dashboard/old/notifications-button/notification-item';
import { SystemNotification, useMarkAllNotificationsAsReadMutation } from '@/lib/state/services/notifications-api';
import MailIcon from '@mui/icons-material/Mail';

/**
 * Props interface for the NotificationsPopover component
 * @interface NotificationsPopoverProps
 */
interface NotificationsPopoverProps {
  /** Element that triggers the popover */
  anchorEl: null | Element;

  /** Array of notification objects to display */
  notifications: SystemNotification[];

  /** Optional callback for when the popover closes */
  onClose?: () => void;

  /** Callback for when "View All" is clicked */
  onViewAll: () => void;

  /** Controls popover visibility */
  open?: boolean;
}

/**
 * NotificationsPopover Component
 *
 * Displays a popover containing a list of notifications with custom styling and scroll behavior.
 * Handles empty states and provides navigation to full notification view.
 *
 * @component
 * @param props - Component props (see NotificationsPopoverProps interface)
 *
 * Features:
 * - Custom scrollbar for notification list
 * - Empty state handling
 * - Fixed width design (380px)
 * - Responsive layout
 * - Material-UI integration
 *
 * Styling:
 * - Uses Tailwind CSS for layout and spacing
 * - Material-UI components for structure
 * - Custom scrollbar styling
 * - Hover states for interactive elements
 *
 * @returns {JSX.Element} Rendered component
 */
export const NotificationsPopover: FC<NotificationsPopoverProps> = ({
  anchorEl,
  notifications,
  onClose,
  onViewAll,
  open = false
}) => {
  const [markAllAsRead] = useMarkAllNotificationsAsReadMutation();

  // Handler for marking all notifications as read
  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead();
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };
  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'left',
    vertical: 'bottom'
  }} onClose={onClose} open={open} slotProps={{
    paper: {
      sx: {
        width: 380
      } // Fixed width for consistent layout
    }
  }}
  // Accessibility attributes
  aria-label="Notifications popover" role="dialog" data-sentry-element="Popover" data-sentry-component="NotificationsPopover" data-sentry-source-file="notifications-popover.tsx">
      {/* Header Section */}
      <Box className="flex items-center justify-between p-4" data-sentry-element="Box" data-sentry-source-file="notifications-popover.tsx">
        <Typography variant="h6" className="mb-0" // Changed from mb-2 to mb-0 to align with icon
      component="h2" aria-label="Notifications list" data-sentry-element="Typography" data-sentry-source-file="notifications-popover.tsx">
          Notifications
        </Typography>
        <Tooltip title="Mark All as Read" placement="left" data-sentry-element="Tooltip" data-sentry-source-file="notifications-popover.tsx">
          <IconButton onClick={handleMarkAllAsRead} size="small" className="text-gray-500 hover:text-gray-700" aria-label="Mark all notifications as read" data-sentry-element="IconButton" data-sentry-source-file="notifications-popover.tsx">
            <MailIcon className="w-5 h-5" data-sentry-element="MailIcon" data-sentry-source-file="notifications-popover.tsx" />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Content Section */}
      {notifications.length === 0 ?
    // Empty State
    <NotificationEmptyState /> :
    // Notification List with Scroll
    <Scrollbar sx={{
      maxHeight: 400
    }}
    // Accessibility for keyboard navigation
    role="list" tabIndex={0}>
          <ul className="divide-y divide-gray-100">
            {notifications.map(notification => <NotificationItem key={notification.id} notification={notification} />)}
          </ul>
        </Scrollbar>}

      {/* Footer Section */}
      <Box className="p-2 border-t border-gray-100" data-sentry-element="Box" data-sentry-source-file="notifications-popover.tsx">
        <button onClick={onViewAll} className="w-full px-3 py-2 text-sm text-center text-gray-700 rounded-md hover:bg-gray-50" aria-label="View all notifications">
          View All Notifications
        </button>
      </Box>
    </Popover>;
};