import { IncomingCallData } from '@/lib/types/rtk-types/call-center';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* Description:
 * This slice manages the call center state in the Redux store.
 * It includes reducers to update the call status, call data, and incoming call status.
 * It also includes reducers to manage the incoming and on hold call queues.
 */

export interface QueueCall {
  id: number;
  call_sid?: string;
  customer_store_id: number;
  name: string;
  phone_number: string;
  start_time: string;
  // timestamp: string;
  // action: string;
  // claim_call_link: string;
  status:
    | 'idle'
    | 'initiating'
    | 'waiting'
    | 'ringing'
    | 'connected'
    | 'disconnected'
    | 'failed'
    | null;
  sid?: string;
  agent?: string;
}

interface CallCenterState {
  incomingCallStatus: boolean;
  incomingCallData: IncomingCallData | null;
  activeCall: IncomingCallData | null;
  twilioToken: string | null;
  callStatus: string | null;
  callActive: boolean;
  accessToken: string | null;
  identity: string | null;
  incomingQueue: QueueCall[];
  onHoldQueue: QueueCall[];
  widgetVisibility: boolean;
  callCenterName: string | null;
  ivrCurrentCallsCounter: number;
  outboundCallStatus:
    | 'idle'
    | 'initiating'
    | 'waiting'
    | 'ringing'
    | 'connected'
    | 'disconnected'
    | 'failed'
    | null;
  isOutboundCall: boolean;
  outboundCallData: IncomingCallData | null;
  outboundCallId: string | null;
  outboundCallSid: string | null;
}

const initialState: CallCenterState = {
  incomingCallStatus: false,
  incomingCallData: null,
  activeCall: null,
  twilioToken: null,
  callStatus: null,
  callActive: false,
  accessToken: null,
  identity: null,
  incomingQueue: [],
  onHoldQueue: [],
  widgetVisibility: false,
  callCenterName: null,
  ivrCurrentCallsCounter: 0,
  outboundCallStatus: null,
  isOutboundCall: false,
  outboundCallData: null,
  outboundCallId: null,
  outboundCallSid: null
};

const callCenterSlice = createSlice({
  name: 'callCenter',
  initialState,
  reducers: {
    setWidgetVisibility: (state, action: PayloadAction<boolean>) => {
      state.widgetVisibility = action.payload;
    },
    updateCallCenterName: (state, action: PayloadAction<string | null>) => {
      state.callCenterName = action.payload;
    },
    setOutboundCallData: (state, action: PayloadAction<IncomingCallData>) => {
      state.outboundCallData = action.payload;
      state.isOutboundCall = true;
      state.outboundCallId = action.payload.sid || null;
    },
    resetCallData: (state) => {
      state.isOutboundCall = false;
      state.outboundCallData = null;
      state.incomingCallData = null;
      state.outboundCallId = null;
      state.outboundCallSid = null;
      state.callStatus = 'disconnected';
      state.outboundCallStatus = 'disconnected';
      state.callActive = false;
    },
    setOutboundCall: (state, action) => {
      state.isOutboundCall = true;
      state.outboundCallSid = action.payload;
    },
    resetOutboundCall: (state) => {
      state.isOutboundCall = false;
      state.outboundCallSid = null;
    },
    setIncomingCallData: (
      state,
      action: PayloadAction<IncomingCallData | null>
    ) => {
      // console.log(
      //   'Call Center Slice - Setting incoming call data:',
      //   action.payload
      // );
      if (action.payload === null) {
        state.incomingCallData = null;
        state.widgetVisibility = false;
        state.incomingQueue = [];
        state.activeCall = null;
      } else {
        state.incomingCallData = {
          ...action.payload,
          status: action.payload.status || 'ringing'
        };
        state.widgetVisibility = true;
      }
    },
    setActiveCall: (state, action: PayloadAction<IncomingCallData | null>) => {
      state.activeCall = action.payload;
    },

    updateOutboundCallStatus: (
      state,
      action: PayloadAction<CallCenterState['outboundCallStatus']>
    ) => {
      state.outboundCallStatus = action.payload;
    },
    updateOutboundCallData: (state, action) => {
      state.outboundCallData = action.payload;
      // Update other relevant fields, but don't change isOutboundCall
    },

    updateIsCallActive: (state, action: PayloadAction<boolean>) => {
      state.callActive = action.payload;
      state.widgetVisibility = action.payload;
    },

    updateIncomingCallStatus: (state, action: PayloadAction<boolean>) => {
      state.incomingCallStatus = action.payload;
    },

    setTwilioToken: (state, action: PayloadAction<string | null>) => {
      state.twilioToken = action.payload;
    },

    updateCallStatus: (state, action: PayloadAction<string>) => {
      state.callStatus = action.payload;
      if (action.payload === 'disconnected') {
        state.isOutboundCall = false;
        state.outboundCallData = null;
        state.incomingCallData = null;
        state.callActive = false;
      }
    },

    //

    identity: (state, action: PayloadAction<string | null>) => {
      state.identity = action.payload;
    },

    addOnHoldQueue: (state, action: PayloadAction<QueueCall>) => {
      state.onHoldQueue.push(action.payload);
    },

    addCallToQueue: (state, action: PayloadAction<QueueCall>) => {
      const existingCall = state.incomingQueue.find(
        (call) => call.id === action.payload.id
      );
      if (!existingCall) {
        state.incomingQueue.push(action.payload);
      }
    },

    removeCallFromQueue: (state, action: PayloadAction<number>) => {
      state.incomingQueue = state.incomingQueue.filter(
        (call) => call.id !== action.payload
      );
      // state.activeCall =
      //   state.incomingQueue.length > 0 ? state.incomingQueue[0] : null;

      // if (state.incomingQueue.length === 0) {
      //   state.widgetVisibility = false;
      // }
    },

    addIvrCurrentCallsCounter: (state) => {
      state.ivrCurrentCallsCounter += 1;
    },

    subtractIvrCurrentCallsCounter: (state) => {
      if (state.ivrCurrentCallsCounter > 0) {
        state.ivrCurrentCallsCounter -= 1;
      }
    }
  }
});

export const {
  updateCallCenterName,
  setWidgetVisibility,
  setActiveCall,
  setIncomingCallData,
  updateOutboundCallStatus,
  updateIsCallActive,
  updateIncomingCallStatus,
  setTwilioToken,
  updateCallStatus,
  identity,
  addOnHoldQueue,
  addCallToQueue,
  removeCallFromQueue,
  addIvrCurrentCallsCounter,
  subtractIvrCurrentCallsCounter,
  setOutboundCallData,
  resetCallData,
  setOutboundCall,
  resetOutboundCall,
  updateOutboundCallData
} = callCenterSlice.actions;

export default callCenterSlice.reducer;
