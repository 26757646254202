import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/hooks/use-auth';
import { SplashScreen } from '@/components/_shared/splash-screen';
interface AuthGuardProps {
  children: ReactNode;
}
export const AuthGuard: FC<React.PropsWithChildren<AuthGuardProps>> = props => {
  const {
    children
  } = props;
  const {
    isAuthenticated,
    isInitialized
  } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // Don't check on public routes
    const isPublicRoute = router.pathname.startsWith('/authentication/') || router.pathname === '/';
    if (isPublicRoute) {
      setChecked(true);
      return;
    }

    // Only redirect if we're initialized and not authenticated
    if (!isAuthenticated && isInitialized) {
      router.push({
        pathname: '/authentication/login',
        query: {
          returnUrl: router.asPath
        }
      }).catch(console.error);
    } else if (isAuthenticated) {
      setChecked(true);
    }
  }, [router.isReady, router.pathname, isAuthenticated, isInitialized]);

  // Show splash screen in these cases:
  // 1. While authentication is being checked (!checked)
  // 2. While auth is not yet initialized (!isInitialized)
  // 3. While authentication state is changing
  if (!checked || !isInitialized || isInitialized && !isAuthenticated && !checked) {
    return <SplashScreen />;
  }
  return <>{children}</>;
};
AuthGuard.propTypes = {
  children: PropTypes.node
};