/**
 * @file use-pusher.ts
 * @description A custom hook for managing Pusher/Echo WebSocket connections throughout the application.
 * Handles initialization, connection state, cleanup, and reconnection logic.
 *
 * Features:
 * - Automatic initialization when access token is available
 * - Connection state tracking
 * - Cross-tab token synchronization
 * - Automatic cleanup on unmount
 * - Manual reconnection capability
 * - Type-safe error handling
 *
 * Usage:
 * ```typescript
 * const { isInitialized, isConnected, error, reconnect } = usePusher();
 * ```
 */

import { useEffect, useState, useCallback } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

interface PusherConnection {
  bind(
    event: 'connected' | 'disconnected' | 'error',
    callback: (error?: Error) => void
  ): void;
}

interface PusherInstance {
  connection: PusherConnection;
  disconnect(): void;
}

// Extend Window interface to include Echo and Pusher
declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: any;
  }
}

// Hook state interface
interface PusherStatus {
  isInitialized: boolean;
  isConnected: boolean;
  error: Error | null;
}

/**
 * Custom hook for managing Pusher/Echo WebSocket connections
 * @returns {Object} Connection status and reconnect function
 */
export const usePusher = () => {
  // State management for connection status
  const [status, setStatus] = useState<PusherStatus>({
    isInitialized: false,
    isConnected: false,
    error: null
  });

  // Lifecycle tracking to prevent updates after unmount
  const [isMounted, setIsMounted] = useState(true);

  /**
   * Initializes Pusher instance and sets up connection handlers
   * @private
   */
  const initializePusher = useCallback(() => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        setStatus((prev) => ({
          ...prev,
          isInitialized: false,
          error: null
        }));
        return;
      }

      // Cleanup existing connection
      if (window.Echo) {
        window.Echo.disconnect();
      }

      // Configure logging
      Pusher.logToConsole =
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'development';

      // Initialize Echo with Pusher
      window.Pusher = Pusher;
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.NEXT_PUBLIC_PUSHER_KEY,
        cluster: 'us3',
        forceTLS: true,
        authEndpoint: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/broadcasting/allow`,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': process.env
              .NEXT_PUBLIC_SITE_URL as string
          }
        }
      });

      // Connection event handlers
      window.Echo.connector.pusher.connection.bind('connected', () => {
        if (isMounted) {
          setStatus({
            isInitialized: true,
            isConnected: true,
            error: null
          });
        }
      });

      window.Echo.connector.pusher.connection.bind('disconnected', () => {
        if (isMounted) {
          setStatus((prev) => ({
            ...prev,
            isConnected: false
          }));
        }
      });

      window.Echo.connector.pusher.connection.bind('error', (err: Error) => {
        if (isMounted) {
          setStatus((prev) => ({
            ...prev,
            error: err,
            isConnected: false
          }));
          console.error('Pusher connection error:', err);
        }
      });
    } catch (err) {
      if (isMounted) {
        setStatus({
          isInitialized: false,
          isConnected: false,
          error:
            err instanceof Error
              ? err
              : new Error('Failed to initialize Pusher')
        });
      }
      console.error('Pusher initialization error:', err);
    }
  }, [isMounted]);

  /**
   * Handles localStorage token changes for cross-tab synchronization
   * @private
   */
  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === 'accessToken') {
        if (event.newValue) {
          initializePusher();
        } else {
          if (window.Echo) {
            window.Echo.disconnect();
            window.Echo = undefined;
          }
          if (isMounted) {
            setStatus({
              isInitialized: false,
              isConnected: false,
              error: null
            });
          }
        }
      }
    },
    [initializePusher, isMounted]
  );

  // Lifecycle hooks for initialization and cleanup
  useEffect(() => {
    setIsMounted(true);
    initializePusher();

    window.addEventListener('storage', handleStorageChange);

    return () => {
      setIsMounted(false);
      window.removeEventListener('storage', handleStorageChange);

      if (window.Echo) {
        window.Echo.disconnect();
        window.Echo = undefined;
      }
    };
  }, [initializePusher, handleStorageChange]);

  /**
   * Manual reconnection function
   * @public
   */
  const reconnect = useCallback(() => {
    if (status.isInitialized && !status.isConnected) {
      initializePusher();
    }
  }, [status.isInitialized, status.isConnected, initializePusher]);

  return {
    ...status,
    reconnect
  };
};
