import useAvatarColors from '@/lib/hooks/use-avatar-colors';
import { useAppSelector } from '@/lib/state/hooks';
import React from 'react';
interface CustomerAvatarProps {
  firstName?: string | null;
  lastName?: string | null;
  id?: number | null;
  isLoading?: boolean;
  avg_spend_per_visit_for_store_range?: number;
}
const Avatar = ({
  firstName,
  lastName
}: CustomerAvatarProps) => {
  if (!firstName && !lastName) {
    return <>??</>;
  }
  return <>
      {firstName?.slice(0, 1).toUpperCase() ?? ''}
      {lastName?.slice(0, 1).toUpperCase() ?? ''}
    </>;
};
const CustomerAvatar = ({
  firstName,
  lastName,
  id,
  isLoading,
  avg_spend_per_visit_for_store_range
}: CustomerAvatarProps) => {
  const customer = useAppSelector(state => state.communication.customers?.find(customer => customer.id === id));
  // const lastIdDigitsString = id?.toString()?.split('');
  // const oneDigit = lastIdDigitsString?.pop();
  // const tenDigit = lastIdDigitsString?.pop();
  // let lastIdDigits = 0;
  // if (oneDigit && tenDigit) {
  //   lastIdDigits = parseInt(oneDigit + tenDigit);
  // }

  const avatarColors = useAvatarColors(customer ? customer?.avg_spend_per_visit_for_store_range || 0 : avg_spend_per_visit_for_store_range || 0, customer ? customer?.first_name as string : firstName as string, customer ? customer?.last_name as string : lastName as string);
  return <div className={avatarColors} data-sentry-component="CustomerAvatar" data-sentry-source-file="customer-avatar.tsx">
      <Avatar firstName={firstName} lastName={lastName} data-sentry-element="Avatar" data-sentry-source-file="customer-avatar.tsx" />
    </div>;
};
export default CustomerAvatar;