import { FC } from 'react';
import { usePusher } from '@/lib/hooks/notifications/use-pusher';

/**
 * Description:
 * Initializes Pusher for the application.
 * This component is used to initialize Pusher when the app loads.
 *
 * @returns null
 */

const PusherInit: FC = () => {
  // Simply call the hook without any parameters
  usePusher();
  return null;
};

export default PusherInit;
