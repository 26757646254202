import { useState } from 'react';
import WelcomeModalFeature from '@/components/onboarding/welcome-modal/welcome-modal-step-pages/welcome-modal-feature';
import { ChartPieIcon, FunnelIcon, UserPlusIcon } from '@heroicons/react/20/solid';
const WelcomeModalProspectManagement = () => {
  const subheading = 'Streamlined Sales Tracking';
  const title = ' Prospect Management';
  const description = " Easily track and manage your prospects with our intuitive tools. Whether you're adding new prospects, making notes, moving them through the sales cycle, or converting them into revenue-generating customers, our platform will enhance your productivity.";
  const imgSrc = '/assets/img/welcome-modal/prospect-management.png';
  const features = [{
    name: 'Prospect Tracking:',
    description: 'Effortlessly track and manage prospects from initial contact to conversion, streamlining your sales process and improving efficiency.',
    icon: UserPlusIcon
  }, {
    name: 'Sales Cycle Management:',
    description: 'Move prospects through the sales cycle seamlessly, making notes and adjustments as needed to ensure a smooth transition to revenue generation.',
    icon: FunnelIcon
  }, {
    name: 'Business Intelligence Analysis:',
    description: 'Leverage business intelligence data to closely monitor your sales cycle and conversion rate, maximizing opportunities for growth and success.',
    icon: ChartPieIcon
  }];
  const [loaded, setLoaded] = useState(false);
  return <WelcomeModalFeature setLoaded={setLoaded} subheading={subheading} title={title} description={description} features={features} imgSrc={imgSrc} data-sentry-element="WelcomeModalFeature" data-sentry-component="WelcomeModalProspectManagement" data-sentry-source-file="welcome-modal-prospect-management.tsx" />;
};
export default WelcomeModalProspectManagement;