import useNotifications from '@/lib/hooks/notifications/use-notifications';
import { FC } from 'react';

/**
 * Description:
 * This component is used to handle notifications.
 * It uses the useNotifications hook to listen for notifications.
 * It also uses the usePusher hook to listen for Pusher events.
 *
 * @returns null
 *
 */

const NotificationHandler: FC = () => {
  // console.log('NotificationHandler mounted');
  useNotifications();
  return null;
};
export default NotificationHandler;