import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CustomerData,
  PaymentMethod,
  Tier,
  Frequency
} from '@/lib/types/pricing';

interface PricingState {
  loading: boolean;
  error: string | null;
  customerData: CustomerData | null;
  paymentMethod: PaymentMethod | null;
  plans: {
    products: Array<{
      metadata: {
        tier_id: string;
        title: string;
        description: string;
        users_min: number;
        cents_per_seat_monthly: string | number;
        cents_per_seat_yearly: string | number;
        most_popular: string;
        price_id_monthly: string;
        price_id_yearly: string;
      };
      marketing_features: Array<{ name: string }>;
    }>;
  } | null;
  selectedTier: Tier | null;
  selectedPriceId: string | null;
  frequency: Frequency;
}

const initialState: PricingState = {
  loading: false,
  error: null,
  customerData: null,
  paymentMethod: null,
  plans: null,
  selectedTier: null,
  selectedPriceId: null,
  frequency: {
    value: 'annual',
    label: 'Yearly (2 months free)',
    priceSuffix: 'Per user/month, billed annually'
  }
};

export const fetchPricingData = createAsyncThunk(
  'pricing/fetchPricingData',
  async (stripeBillingCustomerId: string) => {
    const [billingResponse, plansResponse, paymentMethodsResponse] =
      await Promise.all([
        fetch(
          `/api/stripe/get-stripe-customer-billing-details?stripeBillingCustomerId=${stripeBillingCustomerId}`
        ),
        fetch('/api/stripe/get-stripe-plans'),
        fetch(
          `/api/stripe/get-stripe-customer-payment-method?stripeBillingCustomerId=${stripeBillingCustomerId}`
        )
      ]);

    if (!billingResponse.ok) {
      throw new Error('Failed to fetch billing details');
    }

    const customerData = await billingResponse.json();
    const plans = await plansResponse.json();
    let paymentMethod = null;

    if (paymentMethodsResponse.ok) {
      const paymentMethodsText = await paymentMethodsResponse.text();
      if (paymentMethodsText) {
        paymentMethod = JSON.parse(paymentMethodsText);
      }
    }

    return {
      customerData,
      plans: plans.data.stripePlans,
      paymentMethod
    };
  }
);

export const updatePaymentMethod = createAsyncThunk(
  'pricing/updatePaymentMethod',
  async ({
    stripeBillingCustomerId,
    newPaymentMethodId
  }: {
    stripeBillingCustomerId: string;
    newPaymentMethodId: string;
  }) => {
    const response = await fetch(
      '/api/stripe/update-stripe-customer-payment-method',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ stripeBillingCustomerId, newPaymentMethodId })
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update payment method');
    }

    const updatedData = await response.json();
    return updatedData;
  }
);

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setSelectedTier: (state, action) => {
      state.selectedTier = action.payload;
    },
    setSelectedPriceId: (state, action) => {
      state.selectedPriceId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPricingData.fulfilled, (state, action) => {
        state.loading = false;
        state.customerData = action.payload.customerData;
        state.plans = action.payload.plans;
        state.paymentMethod = action.payload.paymentMethod;
      })
      .addCase(fetchPricingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch pricing data';
      })
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        state.customerData = action.payload;
      });
  }
});

export const { setFrequency, setSelectedTier, setSelectedPriceId } =
  pricingSlice.actions;

export default pricingSlice.reducer;
