//src/lib/state/slices/notification-bell-slice.ts

/**
 * @file notification-bell-slice.ts
 * @description Redux slice for managing the notification bell state in the application.
 *
 * This slice is responsible for handling the state of notifications in the
 * application's Redux store. It provides functionality to:
 * - Add new notifications
 * - Remove individual notifications
 * - Reset the notification state
 * - Clear all notifications
 *
 * The slice maintains a count of active notifications and an array of notification
 * messages. It ensures that duplicate notifications are not added and that the
 * notification count always reflects the actual number of notifications.
 *
 * @module NotificationBellSlice
 * @author Your Name
 * @lastModified 2023-10-09
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Represents a single notification message.
 */
export interface Message {
  /** The title of the notification */
  title: string;
  /** The channel through which the notification was received */
  channel: string;
  /** The main content of the notification */
  body: string;
  /** The phone number from which the notification originated */
  from_number: string;
  /** The name of the customer associated with the notification */
  customer_name: string;
  /** The store ID of the customer */
  customer_store_id: number;
  /** The direction of the communication (inbound or outbound) */
  direction: 'inbound' | 'outbound';
  /** Indicates whether this is an auto-reply message */
  auto_reply: boolean;
  /** Unique identifier for the notification */
  id: number;
  /** Timestamp of when the notification was sent */
  sent_at: string;
}

/**
 * Represents the state structure for the notification bell.
 */
interface NotificationBellState {
  /** The total number of active notifications */
  value: number;
  /** An array of active notification messages */
  messages: Message[];
}

/**
 * The initial state for the notification bell.
 */
const initialState: NotificationBellState = {
  value: 0,
  messages: []
};

/**
 * The Redux slice for managing notification bell state.
 */
export const notificationBellSlice = createSlice({
  name: 'notificationBell',
  initialState,
  reducers: {
    /**
     * Resets the notification bell state to its initial values.
     * @param state - The current state (modified in place by Immer)
     */
    resetNotificationBell: (state) => {
      state.value = 0;
      state.messages = [];
    },

    /**
     * Adds a new message to the notification bell if it doesn't already exist.
     * @param state - The current state (modified in place by Immer)
     * @param action - The action containing the new message payload
     */
    addMessageNotificationBell: (state, action: PayloadAction<Message>) => {
      const id = action.payload.id;
      if (!state.messages.some((message) => message.id === id)) {
        state.messages.push(action.payload);
        state.value += 1;
      }
    },

    /**
     * Removes a specific message from the notification bell by its ID.
     * @param state - The current state (modified in place by Immer)
     * @param action - The action containing the ID of the message to remove
     */
    removeMessageNotificationBell: (state, action: PayloadAction<number>) => {
      const index = state.messages.findIndex(
        (message) => message.id === action.payload
      );
      if (index !== -1) {
        state.messages.splice(index, 1);
        state.value = state.messages.length;
      }
    },

    /**
     * Clears all messages from the notification bell.
     * @param state - The current state (modified in place by Immer)
     */
    clearMessagesNotificationBell: (state) => {
      state.messages = [];
      state.value = 0;
    }
  }
});

// Export action creators
export const {
  resetNotificationBell,
  addMessageNotificationBell,
  removeMessageNotificationBell,
  clearMessagesNotificationBell
} = notificationBellSlice.actions;

// Export the reducer
export default notificationBellSlice.reducer;
