// File: @/lib/utils/posthog.ts

import posthog from 'posthog-js';
import type { NextRouter } from 'next/router';
import React from 'react';

export const initializePostHog = () => {
  if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      }
    });
  }
  return posthog;
};

export const capturePageView = () => {
  posthog.capture('$pageview');
};

export const setupPostHogRouteCapture = async () => {
  if (typeof window !== 'undefined') {
    const { default: Router } = await import('next/router');
    Router.events.on('routeChangeComplete', capturePageView);
    return () => {
      Router.events.off('routeChangeComplete', capturePageView);
    };
  }
};

export const usePostHogPageView = (router: NextRouter) => {
  React.useEffect(() => {
    // Capture initial page view
    capturePageView();

    // Capture page views on route changes
    const handleRouteChange = () => capturePageView();
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
