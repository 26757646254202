import type { AppProps } from 'next/app';
import type { EmotionCache } from '@emotion/cache';
import type { NextPage } from 'next';
import { FC, ReactElement, useEffect } from 'react';
import Head from 'next/head';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/nextjs';
import { UserbackProvider } from '@userback/react';
import { Analytics } from '@vercel/analytics/react';
import { PostHogProvider } from 'posthog-js/react';
import { DarkModeConsumer, DarkModeProvider } from '@/lib/contexts/dark-mode-context';
import { persistor, store } from '@/lib/state/store';
import { createTheme } from '@/lib/theme';
import { createEmotionCache } from '@/lib/utils/create-emotion-cache';
import Maintenance from '@/pages/maintenance';
import { gtmConfig } from '@/lib/gtm/config';
import { gtm } from '@/lib/gtm/gtm';
import { initializePostHog, usePostHogPageView } from '@/lib/utils/posthog';
import { setupNProgress } from '@/lib/utils/nprogress';
import { setupAxios } from '@/lib/utils/axios-config';
import '@/lib/styles/global.css';
import '@/lib/i18n/i18n';
import { useRouter } from 'next/router';
import PusherInit from '@/lib/services/pusher-initialize/pusher-initialize';
import { AuthProvider } from '@/lib/contexts/auth-context';
import ErrorBoundary from '@/components/_shared/error-boundary/Error';
import { PersistentLayout } from '@/layouts/persistent-layout';
import TailwindIndicator from '@/lib/utils/tailwind-indicator';
export type GetLayoutFn = (page: ReactElement) => ReactElement;
type NextPageWithLayout = NextPage & {
  getLayout?: GetLayoutFn;
};
type EnhancedAppProps = AppProps & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};
const clientSideEmotionCache = createEmotionCache();
setupNProgress();
setupAxios();
const App: FC<EnhancedAppProps> = props => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    ...rest
  } = props;
  const router = useRouter();
  usePostHogPageView(router);
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);
  const getLayout = Component.getLayout ?? undefined;
  const maintenance: boolean | undefined = false;
  if (maintenance) {
    return <Maintenance />;
  }
  return <Sentry.ErrorBoundary fallback={({
    error
  }) => <ErrorBoundary error={error} />} data-sentry-element="unknown" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
        <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
          <title>RipeMetrics</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        </Head>

        <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-source-file="_app.tsx">
          <PersistGate loading={null} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="_app.tsx">
            <PusherInit data-sentry-element="PusherInit" data-sentry-source-file="_app.tsx" />
            <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
              <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
                <DarkModeProvider data-sentry-element="DarkModeProvider" data-sentry-source-file="_app.tsx">
                  <DarkModeConsumer data-sentry-element="DarkModeConsumer" data-sentry-source-file="_app.tsx">
                    {({
                    darkMode
                  }) => <ThemeProvider theme={createTheme({
                    responsiveFontSizes: darkMode.responsiveFontSizes,
                    mode: darkMode.theme
                  })}>
                        <PostHogProvider client={initializePostHog()}>
                          <CssBaseline />
                          <Toaster position="top-right" reverseOrder={true} />
                          <UserbackProvider token={process.env.NEXT_PUBLIC_USERBACK_TOKEN as string}>
                            <TailwindIndicator />
                            <PersistentLayout getLayout={getLayout}>
                              <ErrorBoundary>
                                <Component {...rest} />
                              </ErrorBoundary>
                            </PersistentLayout>
                          </UserbackProvider>
                        </PostHogProvider>
                      </ThemeProvider>}
                  </DarkModeConsumer>
                </DarkModeProvider>
              </AuthProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
        <Analytics data-sentry-element="Analytics" data-sentry-source-file="_app.tsx" />
      </CacheProvider>
    </Sentry.ErrorBoundary>;
};
export default App;